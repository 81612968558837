import styled from 'styled-components';

import { Button } from 'components/Button/Button';
import { Icon } from 'components/Icon/Icon';
import { cssBreakpoints } from 'theme/theme';

type Props = {
  className?: string;
  iconOnly: boolean;
  qaId: string;
};

export const SearchButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const SearchButtonTextContainer = styled.span<{ $hide: boolean }>`
  display: none;
  margin-left: 7px;
  margin-right: 4px;

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    display: ${(props) => (props.$hide ? 'none' : 'block')};
  }
`;

export const PostSearchButton = styled(
  ({ className, iconOnly, qaId }: Props) => (
    <Button
      type="submit"
      variant="primary"
      aria-label="Search"
      className={className}
      data-qa-id={qaId}
      size="large"
    >
      <Icon name="search" size={20} />

      <SearchButtonTextContainer $hide={iconOnly}>
        {getText('Search')}
      </SearchButtonTextContainer>
    </Button>
  ),
)<{ iconOnly: boolean }>`
  @media all and (max-width: ${cssBreakpoints.smUp}) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  @media all and (min-width: ${cssBreakpoints.smUp}) {
    gap: ${(props) => (props.iconOnly ? 0 : '8px')};
  }
`;
